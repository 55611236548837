import Axios from "axios";
import webserviceWrapper from "../helpers/webserviceWrapper";


export const StatisticsService = {
    GetStatisticsReportToken,
    GetStatisticsDropdownData,
    GetReport,
    GetEmbeddedTokenData,
    LoadStatisticsReports
};

/* <summary>
    date: 22/11/2022
    Name: AS
    description: service api call to get authtoken for powerbi reports
    <summary> */

function GetStatisticsReportToken(loggedInUserID, userTypeId) {
    var result = webserviceWrapper.get(`/Statistics/GetStatisticsReportToken?loggedInUserID=${loggedInUserID}&userTypeId=${userTypeId}`);
    return result;
}


/* <summary>
    date: 22/11/2022
    Name: AS
    description: service api call to get statistics dropdown data
    <summary> */
function GetStatisticsDropdownData(userTypeId) {
    return webserviceWrapper.get(`/Statistics/GetStatisticsDropdownData?userTypeId=${userTypeId}`);
}

/* <summary>
    date: 22/11/2022
    Name: AS
    description: service api call to return powerbi report
    <summary> */
function GetReport(reportId, groupId, userId, userTypeId) {
    return webserviceWrapper.get(`/Statistics/GetEmbedReportModel?groupId=${groupId}&reportId=${reportId}&userId=${userId}&userTypeId=${userTypeId}`);
}

function GetEmbeddedTokenData(reportId, groupId, userId, userTypeId) {
    return webserviceWrapper.get(`/Statistics/GenerateEmbedToken?groupId=${groupId}&reportId=${reportId}&userId=${userId}&userTypeId=${userTypeId}`);
}


function LoadStatisticsReports(userID, userTypeId, reportName) {
    var response = null;
    if (userTypeId == 2 || userTypeId == 3) {
        if (reportName == 'Number of IHM Executives IU') // 1 
            response = webserviceWrapper.get(`/Statistics/GetNoOfIHMExecutivesInIUNewDataPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of hazmat items per client/ship IU') // 2 
            response = webserviceWrapper.get(`/Statistics/GetNoOfHazmatItemClientShipInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'List of ships by date last tracking sheet updated IU') // 3 
            response = webserviceWrapper.get(`/Statistics/GetShipSortedByLastTrackingSheetInIUNew1PBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Active / InActive Ships per IU') // 4 
            response = webserviceWrapper.get(`/Statistics/GetNoOfClientShipPerUserInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of hazmat present per ship IU') // 5
            response = webserviceWrapper.get(`/Statistics/GetNoOfHazmatPresentShipInIUNewChangesPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of items pending for MD/SDoC IU') // 6 
            response = webserviceWrapper.get(`/Statistics/GetNoOfItemPendingForMDSDOCNewInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of items with 0 communication, with 1,2,3 reminder IU') // 7 
            response = webserviceWrapper.get(`/Statistics/GetNoOfItemWith0CommunicationInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of MD/SDoC resent by IHM Executive for corrections IU') // 8 
            response = webserviceWrapper.get(`/Statistics/GetNoOfRFCMDSDoCForUserNewInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of MD/SDoC sent and pending for IHM Executive review IU') // 9 
            response = webserviceWrapper.get(`/Statistics/GetNoOfPendingMDSDoCForUserInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of MD/SDoC sent and reviewed by IHM Executive IU') // 10 
            response = webserviceWrapper.get(`/Statistics/GetNoOfReviewedMDSDoCForUserNewInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of MD/SDoC received from supplier per ship IU') // 11 
            response = webserviceWrapper.get(`/Statistics/GetNoOfMDSDOCReceivedPerShipNewInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of non responsive supplier IU') // 12
            response = webserviceWrapper.get(`/Statistics/GetNoOfNonResponsiveSupplierInIUAPIPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of PO line items checked per client/ship/handler/year/month IU') // 13 
            response = webserviceWrapper.get(`/Statistics/GetNoOfPOLineItemCheckedNewChangesInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of POs per client/ship/year/month IU') // 14
            response = webserviceWrapper.get(`/Statistics/GetNoOfPOPerClientShipInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of SoC due for renewal IU') // 15 
            response = webserviceWrapper.get(`/Statistics/GetNoOfSOCDueForRenewalInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of suppliers per client/ship focused IU') // 16 
            response = webserviceWrapper.get(`/Statistics/GetNoOfSupplierPerClientShipInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of suspected hazmat items pending for MD/SDoC submission IU') // 17 
            response = webserviceWrapper.get(`/Statistics/GetNoOfSuspectedHazmatPendingMDSDoCNewChangesInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of suspected hazmat items shortlisted in a ship IU') // 18 
            response = webserviceWrapper.get(`/Statistics/GetNoOfSuspectedHazmatInShipInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of updated IHM Profiles IU') // 19 
            response = webserviceWrapper.get(`/Statistics/GetNoOfUpdatedUsersInIUNewPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of PO Items Shortlisted IU') // 20 
            response = webserviceWrapper.get(`/Statistics/GetNoOfPOShortListedInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Active vs Passive Ships / Clients IU') // 21
            response = webserviceWrapper.get(`/Statistics/GetNoOfActivePassiveShipClientInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by Hazmat & types of Hazmat present IU')
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipWithHazmatInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Total number of user accounts IU') // 23 
            response = webserviceWrapper.get(`/Statistics/GetNoOfTotalUserAccountInIUNewPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Total Historical Data for Client Vessels IU') // 24 
            response = webserviceWrapper.get(`/Statistics/GetTotalHistoricDataForClientShipInIUNewPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Types(Number of ship with Asbestos,lead etc.) IU') // 25 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipWithTableABHazmatInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of supplier with no email contacts IU') // 26 
            response = webserviceWrapper.get(`/Statistics/GetNoOfSuppliersWithNoEmailInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Clients IU') // 27 
            response = webserviceWrapper.get(`/Statistics/GetNoOfClientsInTypeIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Clients sorted by ship counts IU') // 28 
            response = webserviceWrapper.get(`/Statistics/GetNoOfClientsByShipInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of clients sorted/grouped by total number of contract year IU') // 29 
            response = webserviceWrapper.get(`/Statistics/GetNoOfClientsContractDurationInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Clients Year or Month wise to see the progress IU') // 30 
            response = webserviceWrapper.get(`/Statistics/GetNoOfClientsYearMonthWiseInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of MD Received IU') // 31 
            response = webserviceWrapper.get(`/Statistics/GetNoOfMDReceivedNewInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Active / InActive Ships per Client IU') // 32 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipsInTypeIUNewPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == "Number of ship added during client's 1st contract period IU") // 33 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipAddedInClientInitialContractYearInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships completed Year1, Year2 IU') // 34 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipCompletedYearInIUNewPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships due for contract renewal IU') // 35 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipDueForRenewalInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by date of construction IU') // 36 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipByDateOfConstructionInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of ships containing hazmat present IU') // 37
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipGroupedByHazmatInIUNewPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by shipbuilder IU') // 38 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipGroupedByShipBuilderInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by shipowner / Manager IU') // 39 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipGroupedByShipOwnerInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by the classification society IU') // 40
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipGroupedByClassificationSocietyInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by the port of registery IU') // 41 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipsByPortOfRegistryInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Client SOC Expiry Notification IU') // 42
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipBySOCValidityExpiryNewChangesInIU1PBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by type of vessel IU') // 43
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipsByVesselTypeInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Outstanding PO Items wherein MD / SDoC not received IU') // 44 
            response = webserviceWrapper.get(`/Statistics/GetNoMDSDoCRcvdForPOInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == "Number of Ships sorted/grouped by number of documents pending IU") // 45 
            response = webserviceWrapper.get(`/Statistics/GetDocumentsUploadedByShipInIUPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Ships sorted by more number of non responsive suppliers & no email contact suppliers IU') // 46 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipByNonResponsiveSupplierInIUUpdatedPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of MD/SDoC per ship IU') // 47
            response = webserviceWrapper.get(`/Statistics/GetNoOfMDSDoCPerShipInIUNewPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
    }
    else {
        if (reportName == 'Number of IHM Executives') // 1 
            response = webserviceWrapper.get(`/Statistics/GetNoOfIHMExecutivesPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of hazmat items per client/ship') // 2 
            response = webserviceWrapper.get(`/Statistics/GetNoOfHazmatItemClientShipPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'List of ships sorted by date last tracking sheet updated') // 3 
            response = webserviceWrapper.get(`/Statistics/GetShipSortedByLastTrackingSheetNewPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Active / InActive Ships per Client') // 4 
            response = webserviceWrapper.get(`/Statistics/GetNoOfClientShipPerUserPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of hazmat present per ship') // 5
            response = webserviceWrapper.get(`/Statistics/GetNoOfHazmatPresentShipNewChangesPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of items pending for MD/SDoC') // 6 
            response = webserviceWrapper.get(`/Statistics/GetNoOfItemPendingForMDSDOCNewPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of items with 0 communication, with 1,2,3 reminder') // 7 
            response = webserviceWrapper.get(`/Statistics/GetNoOfItemWith0CommunicationNewPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of MD/SDoC resent by IHM Executive for corrections') // 8 
            response = webserviceWrapper.get(`/Statistics/GetNoOfRFCMDSDoCForUserNewPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of MD/SDoC sent and pending for IHM Executive review') // 9 
            response = webserviceWrapper.get(`/Statistics/GetNoOfPendingMDSDoCForUserPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of MD/SDoC sent and reviewed by IHM Executive') // 10 
            response = webserviceWrapper.get(`/Statistics/GetNoOfReviewedMDSDoCForUserNewPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of MD/SDoC received from supplier per ship') // 11 
            response = webserviceWrapper.get(`/Statistics/GetNoOfMDSDOCReceivedPerShipNewPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of non responsive supplier') // 12
            response = webserviceWrapper.get(`/Statistics/GetNoOfNonResponsiveSupplierPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);

        else if (reportName == 'Number of PO line items checked per client/ship/handler/year/month') // 13 
            response = webserviceWrapper.get(`/Statistics/GetNoOfPOLineItemUpdatedPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of POs per client/ship/year/month') // 14
            response = webserviceWrapper.get(`/Statistics/GetNoOfPOPerClientShipPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of SoC due for renewal') // 15 
            response = webserviceWrapper.get(`/Statistics/GetNoOfSOCDueForRenewalPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of suppliers per client/ship focused') // 16 
            response = webserviceWrapper.get(`/Statistics/GetNoOfSupplierPerClientShipPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of suspected hazmat items pending for MD/SDoC submission') // 17 
            response = webserviceWrapper.get(`/Statistics/GetNoOfSuspectedHazmatPendingMDSDoCNewChangesPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of suspected hazmat items shortlisted in a ship') // 18 
            response = webserviceWrapper.get(`/Statistics/GetNoOfSuspectedHazmatInShipNewPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of updated IHM Profiles') // 19 
            response = webserviceWrapper.get(`/Statistics/GetNoOfUpdatedUsersPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of PO Items Shortlisted') // 20 
            response = webserviceWrapper.get(`/Statistics/GetNoOfPOShortListedPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Active vs Passive Ships / Clients') // 21
            response = webserviceWrapper.get(`/Statistics/GetNoOfActivePassiveShipClientPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by Hazmat & types of Hazmat present') // 22 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipWithHazmatPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);

        else if (reportName == 'Total number of user accounts') // 23 
            response = webserviceWrapper.get(`/Statistics/GetNoOfTotalUserAccountPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Total Historical Data for Client Vessels') // 24 
            response = webserviceWrapper.get(`/Statistics/GetTotalHistoricDataForClientShipPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Types(Number of ship with Asbestos,lead etc.)') // 25 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipWithTableABHazmatPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of supplier with no email contacts') // 26 
            response = webserviceWrapper.get(`/Statistics/GetNoOfSuppliersWithNoEmailPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Clients') // 27 
            response = webserviceWrapper.get(`/Statistics/GetNoOfClientsNewDataPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Clients sorted by ship counts') // 28 
            response = webserviceWrapper.get(`/Statistics/GetNoOfClientsByShipPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of clients sorted/grouped by total number of contract year') // 29 
            response = webserviceWrapper.get(`/Statistics/GetNoOfClientsContractDurationPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Clients Year or Month wise to see the progress') // 30 
            response = webserviceWrapper.get(`/Statistics/GetNoOfClientsYearMonthWisePBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of MD Received') // 31 
            response = webserviceWrapper.get(`/Statistics/PBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Active / InActive Ships per Client') // 32 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipsPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);

        else if (reportName == "Number of ship added during client's 1st contract period") // 33 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipAddedInClientInitialContractYearNewPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships completed Year1, Year2') // 34 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipCompletedYearPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships due for contract renewal') // 35 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipDueForRenewalPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by date of construction') // 36 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipByDateOfConstructionPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of ships containing hazmat present') // 37
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipGroupedByHazmatPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by shipbuilder') // 38 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipGroupedByShipBuilderPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by shipowner') // 39 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipGroupedByShipOwnerNewDataPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by the classification society') // 40
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipGroupedByClassificationSocietyPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by the port of registery') // 41 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipsByPortOfRegistryPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Client SOC Expiry Notification') // 42
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipBySOCValidityExpiryNewChanges1PBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);

        else if (reportName == 'Number of Ships grouped by type of vessel') // 43
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipsByVesselTypePBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Outstanding PO Items wherein MD / SDoC not received') // 44 
            response = webserviceWrapper.get(`/Statistics/GetNoMDSDoCRcvdForPONewPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == "Number of Ships sorted/grouped by number of documents pending") // 45 
            response = webserviceWrapper.get(`/Statistics/GetDocumentsUploadedByShipNewPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Ships sorted by more number of non responsive suppliers & no email contact suppliers') // 46 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipByNonResponsiveSupplierUpdatedPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of MD/SDoC per ship') // 47
            response = webserviceWrapper.get(`/Statistics/GetNoOfMDSDoCPerShipNewPBi?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
    }
    return response;
}